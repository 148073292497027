<template>
    <div class="box">
        <h2>给经理发消息</h2>
        <!-- <div class="form-item" style="margin-right: 30px; margin-top: 20px">
            <span>留言主题</span>
            <el-select v-model="message" name="message" style="width: 120px" size="small" class="get-method" placeholder="全部">
                <el-option v-for="item in checkMessage" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
        </div>
        <div class="content">
            <el-input type="textarea" :rows="5" placeholder="请输入留言内容" v-model="content"> </el-input>

            <el-button @click="submit">提交留言</el-button>
        </div> -->
        <div class="form-row">
            <div class="form-item">
                <el-button class="goMessage" @click.native="dialogVisible1 = true"> <img style="width: 14px" src="~img/alibaba/goMessage.png" alt="" /> 我要留言</el-button>
                <div id="app-frist">
                    <el-dialog title="我要留言" :visible.sync="dialogVisible1" width="30%" :before-close="handleClose_message" style="padding-bottom: 10px">
                        <div class="form-item" style="margin-top: 20px">
                            <span style="margin-right: 0px; width: 75px">留言主题</span>
                            <el-select v-model="message" name="message" style="width: 473px" size="small" class="get-method" placeholder="全部">
                                <el-option v-for="item in checkMessage" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                            </el-select>
                        </div>
                        <span slot="footer" class="dialog-footer" style="text-align: left">
                            <span style="margin-right: 0px; float: left; width: 60px">留言内容</span>
                            <el-input type="textarea" :rows="3" placeholder="请输入留言内容" v-model="content" style="width: 418px"> </el-input>
                            <!-- <el-button @click="dialogVisible1 = false">取 消</el-button> -->
                            <el-button type="primary" @click="submit" style="padding: 10px 40px; margin-top: 20px; text-align: left; margin-left: 57px">提 交 留 言</el-button>
                        </span>
                    </el-dialog>
                </div>
                <div class="form-item" style="margin-right: 30px">
                    <span>留言主题</span>
                    <el-select v-model="message" name="message" style="width: 120px" size="small" class="get-method" placeholder="全部">
                        <el-option v-for="item in checkMessage" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </div>
                <span>时间范围</span>
                <el-date-picker v-model="timeArea" size="small" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                <el-button class="search-btn" @click="search"> <img src="~img/alibaba/search.png" />搜索</el-button>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
                <el-image fit="cover" round :src="require('img/alibaba/member-1.png')"></el-image>
                <div class="item-inner">
                    <div class="top">
                        {{ item.user_name }} <span>{{ $util.timeStampTurnTime(item.create_time) }}</span>
                    </div>
                    <div class="content">
                        {{ item.content }}
                    </div>
                    <div class="return-msg">
                        <span>回复：</span>{{ item.reply }}
                        <div id="app">
                            <el-button type="text" @click="del(item)">
                                <span class="delete"><img src="~img/alibaba/del.png" alt="" /></span>
                            </el-button>

                            <!-- <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
                                <span>确定要删除该条留言吗？</span>
                                <span slot="footer" class="dialog-footer">
                                    <el-button @click="dialogVisible = false">取 消</el-button>
                                    <el-button type="primary" @click="del(item)">确 定</el-button>
                                </span>
                            </el-dialog> -->
                        </div>
                    </div>
                    <!-- <el-dialog></el-dialog> -->
                </div>
            </div>
        </div>

        <!-- <div>
            <button type="text" @click="popupclick" class="elspanbutton">查看相似</button>

            <el-dialog title="相似媒体" :visible.sync="dialogTableVisible"></el-dialog>
        </div> -->
        <div class="jump" :data="list">
            <el-pagination class="jump-count" style="float: right" :page-size="pageSize" @current-change="handlePageChange" :current-page.sync="currentPage" layout="prev, pager, next, jumper" ref="pagination" :total="total"> </el-pagination>
            <div class="total-number">共{{ list.length }}条</div>
        </div>
    </div>
</template>

<script>
import { msgSend } from "@/api/member/member"
import { msgGet, delMsg } from "@/api/member/member"
import { done } from "nprogress"

export default {
    name: "message",
    components: {},
    data: () => {
        return {
            loading: true,
            content: "",
            subject: "all",
            checkMessage: [
                { label: "全部", value: "all" },
                { label: "物流", value: "logistics" },
                { label: "质量", value: "quality" },
                { label: "售后", value: "sale" }
            ],
            message: "all",
            dialogVisible: false,
            dialogVisible1: false,
            // 时间区间
            list: [],
            total: 0,
            pageSize: 3,
            currentPage: 1,
            timeArea: [],
            beginTime: "",
            endTime: "",
            message: "all"
        }
    },
    created() {
        this.getMsgGet()
    },
    mounted() {
        let self = this
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    methods: {
        handleClose_message(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done()
                })
                .catch((_) => {})
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done()
                })
                .catch((_) => {})
        },
        submit() {
            msgSend({
                content: this.content,
                subject: this.message
            })
                .then((res) => {
                    if (res.code != 0) return this.$message("留言失败")
                    this.$message("提交成功")
                    this.content = ""
                    // this.handleClose(done)
                    this.dialogVisible1=false
                })
                .catch((e) => {
                    this.$message("留言失败")
                })
        },
        getMsgGet() {
            msgGet({
                begin_time: this.beginTime,
                end_time: this.endTime,
                page: this.currentPage,
                page_size: this.pageSize,
                subject: this.message
            })
                .then((res) => {
                    this.loading = false
                    if (res.code != 0) {
                        throw new Error(res.message)
                    }

                    let { count, list, page_count } = res.data
                    this.list = list
                    this.total = count
                    console.log(this.list)
                })
                .catch((e) => {
                    this.loading = false
                    this.$message(e.message)
                })
        },
        search() {
            console.log(this.timeArea.length)
            if (this.timeArea.length == 0) {
                this.beginTime = "946656000"
                this.endTime = "4070880000"
            } else {
                this.beginTime = this.timeArea[0].getTime() / 1000
                this.endTime = this.timeArea[1].getTime() / 1000
            }
            this.loading = true
            this.getMsgGet()
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.refresh()
        },
        handlePageChange() {
            this.getMsgGet()
        },

        // 删除留言
        del(e) {
            console.log(e)

            delMsg({
                message_id: e.message_id
            })
                .then((res) => {
                    if (res.code == 0) {
                        console.log(res)
                    }
                    this.getMsgGet()
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;

    h2 {
        font-size: 24px;
        color: $ns-text-color-black;
        padding-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;

        .balance {
            margin-left: 10px;
            color: red;
            vertical-align: -2px;
        }

        .hints {
            margin-left: 20px;
            font-size: 12px;
            color: $ns-text-color-gray;
            font-weight: 400;
            vertical-align: -1px;
        }
    }

    .content {
        padding-top: 30px;

        .el-button {
            background-color: $base-color;
            font-size: $ns-font-size-lg;
            color: #ffffff;
            float: right;
            margin-top: 20px;
        }
    }
}
.box {
    width: 100%;
    position: relative;

    h2 {
        font-size: 24px;
        color: $ns-text-color-black;
        padding-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;

        .balance {
            margin-left: 10px;
            color: red;
            vertical-align: -2px;
        }

        .hints {
            margin-left: 20px;
            font-size: 12px;
            color: $ns-text-color-gray;
            font-weight: 400;
            vertical-align: -1px;
        }
    }

    .form-row {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .form-item {
            display: flex;
            align-items: right;
            color: $ns-text-color-black;

            span {
                margin-right: 10px;
                line-height: 36px;
            }

            .search-btn {
                height: 32px;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $ns-font-size-base;
                color: #ffffff;
                background-color: $base-color;
                margin-left: 10px;

                img {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }
        }
    }

    .list {
        padding-top: 30px;

        .item {
            border: 1px solid #f4f4f4;
            box-sizing: border-box;
            padding: 20px;
            margin-bottom: 10px;
            display: flex;
            align-items: flex-start;

            .el-image {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: #f1f1f1;
                box-sizing: border-box;
                padding: 10px;
            }

            .item-inner {
                width: calc(100% - 80px);
                margin-left: 20px;

                .top {
                    font-size: 20px;
                    color: $ns-text-color-black;

                    span {
                        color: $ns-text-color-gray;
                        font-size: $ns-font-size-base;
                    }
                }

                .content {
                    color: $ns-text-color-gray;
                    font-size: $ns-font-size-base;
                    margin: 10px 0;
                }

                .return-msg {
                    padding: 20px;
                    background-color: #f8f8f8;
                    font-size: $ns-font-size-lg;
                    color: $ns-text-color-black;
                    position: relative;

                    span {
                        color: $ns-text-color-gray;
                        margin-right: 5px;
                    }
                    :hover {
                        color: #ed6030;
                    }
                }
            }
        }
    }
}
.delete {
    font-size: $ns-font-size-base;
    color: $base-color;
    cursor: pointer;
    float: right;
    img {
        width: 40px;
    }
}
.jump {
    font-size: 13px;
    color: #606266;
    .total-number {
        float: right;
        //   line-height: 16px;
        margin-top: 5px;
    }
}
.goMessage {
    padding: 10px 20px;
    margin-right: 300px;
    margin-left: 20px;
    background-color: #ed6030;
    color: #ffffff;
}
#app {
    position: absolute;
    right: 10px;
    top: 0px;
}
</style>
